/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import {
  AppContextProvider,
  ErrorBoundary,
  MonitoringContextProvider,
} from 'taulia-ui';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import './main.scss';
import {
  LoginPage,
  PromotionalPage,
  RecoveryCodeLogin,
  SecondFactorLogin,
} from './pages/LoginPage';
import {
  AppSetup,
  ChooseMethod,
  RecoveryCodes,
  SMSSetup,
} from './pages/Authentication';
import { NewPassword, ResetPassword } from './pages/ResetPassword';

import TRANSLATIONS from './translations/translations';
import LoginError from './components/LoginError';
import { App } from './pages/App';

i18next.use(initReactI18next).init({
  partialBundledLanguages: true,
  fallbackLng: 'en-US',
  interpolation: { escapeValue: false },
  lng: 'en-US',
  namespaces: ['app', 'bundled', 'lib-app-ui'],
  resources: TRANSLATIONS,
  defaultNS: 'app',
  react: {
    useSuspense: false,
    bindI18n: 'languageChanged',
  },
});

const nonceElement = document.querySelector('[nonce]'); // get the nonce from any other element on the page
const script = window.document.createElement('script');
script.src = 'https://js.hcaptcha.com/1/api.js?render=explicit';
script.id = 'hcaptcha-api-script-id';

if (nonceElement) {
  script.setAttribute(
    'nonce',
    nonceElement.nonce || nonceElement.getAttribute('nonce')
  );
}

window.document.head.appendChild(script);

// Render the App
ReactDOM.render(
  <div>
    <BrowserRouter>
      <AppContextProvider errorComponent={<LoginError />}>
        <ErrorBoundary errorComponent={<LoginError />}>
          <Routes>
            <Route element={<App />}>
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/login/two-factor/recovery"
                element={<RecoveryCodeLogin />}
              />
              <Route
                path="/login/two-factor/promotion"
                element={<PromotionalPage />}
              />
              <Route
                path="/login/two-factor/:type"
                element={<SecondFactorLogin />}
              />
              <Route path="/login/:authRequestId" element={<LoginPage />} />
              <Route
                path="/authentication/choose-method"
                element={<ChooseMethod />}
              />
              <Route
                path="/authentication/setup-application"
                element={<AppSetup />}
              />
              <Route path="/authentication/setup-sms" element={<SMSSetup />} />
              <Route
                path="/authentication/recovery-codes"
                element={<RecoveryCodes />}
              />
              <Route path="/reset-password/new" element={<NewPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />

              <Route path="*" element={<Navigate to="/login" />} />
            </Route>
          </Routes>
        </ErrorBoundary>
      </AppContextProvider>
    </BrowserRouter>
  </div>,
  document.getElementById('root')
);
