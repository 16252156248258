import React, { useState, useEffect, useMemo, useContext } from 'react';
import validate from 'validate.js';
import { Helmet } from 'react-helmet';
import { useLocation, Outlet } from 'react-router-dom';
import {
  Branding,
  Logo,
  useFetch,
  ToastContainer,
  RequestContext,
  Card,
} from 'taulia-ui';
import { useTranslation } from 'react-i18next';
import AuthTypeProvider from '../../contexts/authTypeProvider';
import { useBranding } from '../../hooks';
import UserSettingsContextProvider from '../../contexts/UserSettingsContextProvider';
import GenericError from '../../components/LoginError';
import Logger from '../../utils/Logger';

const DEBUG = JSON.stringify(import.meta.env.NODE_ENV !== 'production');
const logger = new Logger(DEBUG);

function App() {
  const { t } = useTranslation();
  const location = useLocation();
  const [showError, setShowError] = useState(null);
  const request = useContext(RequestContext);

  const { data: regex } = useFetch('/api/email/regex', { request });

  const brandingLoaded = useBranding(request);

  const showApp = useMemo(() => brandingLoaded, [brandingLoaded]);

  useEffect(() => {
    validate.validators.email = value => {
      if (value && value.match(regex.pattern)) {
        return null;
      }
      return '^email';
    };
  }, [regex]);

  const renderTitle = () => {
    // Take the path name, split it by '/' and '-', capitalize the first letter, and add '|' if has multiple sections
    if (location && location.pathname && !location.pathname.includes('login')) {
      return location.pathname
        .split('/')
        .filter(word => word)
        .map(title =>
          title
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        )
        .join(' | ');
    }
    return t('bundled:head.title', {
      portalCompany: Branding.fetchDisplayName(),
    });
  };

  return (
    <div id="application" className="app">
      <Helmet
        title={renderTitle()}
        link={[{ rel: 'shortcut icon', href: Branding.fetchImage('favicon') }]}
      />
      {showApp && (
        <div id="pageContent" className="container">
          <Card
            classNames={['login-page']}
            title="login-page"
            showTitle={false}
          >
            <div className="header">
              <Logo />
            </div>
            <div className="body">
              {showError ? (
                <GenericError />
              ) : (
                <AuthTypeProvider>
                  <UserSettingsContextProvider logger={logger}>
                    <Outlet />
                  </UserSettingsContextProvider>
                </AuthTypeProvider>
              )}
            </div>
          </Card>
          <ToastContainer />
        </div>
      )}
    </div>
  );
}

export default App;
